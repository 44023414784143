import React, { useCallback, useEffect, useState } from "react";

import {
  Card,
  CardBody,
  CardHeader,
  Container,
  InputGroup,
  Input,
  Button,
  InputGroupText,
  CardFooter,
  Badge,
} from "reactstrap";

import { ACTIONS, useEmployees } from "../../providers/employeesProvider";
import { employeesApi } from "../../services/employeeServices";
import EmployeeAssignationsModal from "../../components/admin/employees/EmployeeAssignationsModal";
import EmployeeNotesModal from "../../components/EmployeeNotesModal";

import {
  faSearch,
  faUsers,
  faStickyNote,
  faPen,
  faSync,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { utilsHelper } from "../../helpers/utilsHelper";
import EmployeeModal from "../../components/admin/employees/EmployeeModal";
import InformationModal from "../../components/InformationModal";
import CustomCheckboxWithLabel from "../../components/CustomCheckboxWithLabel";
import AdvanceTableWrapper from "../../components/advanceTable/AdvanceTableWrapper";
import AdvanceTable from "../../components/advanceTable/AdvanceTable";
import AdvanceTablePagination from "../../components/advanceTable/AdvanceTablePagination";
import { useAuth } from "../../providers/authProvider";
import SafetyCertificationsModal from "../../components/SafetyCertificationsModal";

const DEFAULT = "1";

const columns = (
  onEditNotes,
  onSeeAssignations,
  onEdit,
  onSafatyDocs,
  IS_READ_ONLY
) => [
  {
    accessor: "firstName",
    Header: "First Name",
    headerProps: { className: "text-truncate" },
    cellProps: { className: "text-truncate" },
    width: 150,
    Cell: (rowData) => {
      const { firstName, preferredFirstName } = rowData.row.original;
      return preferredFirstName
        ? `${firstName} (${preferredFirstName})`
        : firstName;
    },
  },
  {
    accessor: "lastName",
    Header: "Last Name",
    headerProps: { className: "text-truncate" },
    cellProps: { className: "text-truncate" },
    width: 150,
    Cell: (rowData) => {
      const { lastName } = rowData.row.original;
      return lastName || "-";
    },
  },
  {
    accessor: "email",
    Header: "Email",
    headerProps: { className: "text-truncate" },
    cellProps: { className: "text-truncate" },
    width: 200,
    Cell: (rowData) => {
      const { email } = rowData.row.original;
      return email || "-";
    },
  },
  {
    accessor: "phone",
    Header: "Phone",
    headerProps: { className: "text-truncate" },
    cellProps: { className: "text-truncate" },
    width: 100,
    Cell: (rowData) => {
      const { phone } = rowData.row.original;
      return phone || "-";
    },
  },
  {
    accessor: "role.name",
    Header: "Role",
    headerProps: { className: "text-truncate text-center" },
    cellProps: { className: "text-truncate text-center" },
    width: 150,
    Cell: (rowData) => {
      const { role } = rowData.row.original;
      return <Badge>{role?.name || "-"}</Badge>;
    },
  },
  {
    accessor: "department",
    Header: "Division",
    headerProps: { className: "text-truncate text-center" },
    cellProps: { className: "text-truncate text-center" },
    width: 100,
    Cell: (rowData) => {
      const { sourceKey } = rowData.row.original;
      return sourceKey || "-";
    },
  },
  {
    accessor: "id",
    Header: "Actions",
    disableSortBy: true,
    headerProps: { className: "text-truncate text-center" },
    cellProps: { className: "text-truncate text-center" },
    width: 300,
    Cell: (rowData) => (
      <div className="d-flex align-items-center justify-content-center">
        <Button
          color="seven"
          size="sm"
          className="rounded mr-2 d-flex align-items-center"
          onClick={() => onSafatyDocs(rowData.row.original)}
        >
          <FontAwesomeIcon icon={faExclamationTriangle} className="mr-1" />
          <span>Safety Docs</span>
        </Button>
        <Button
          color="info"
          size="sm"
          className="rounded d-flex align-items-center"
          onClick={() => onEditNotes(rowData.row.original.id)}
        >
          <FontAwesomeIcon icon={faStickyNote} className="mr-1" />
          <span>Notes</span>
        </Button>
        <Button
          hidden={utilsHelper.isBetaProd()}
          color="success"
          size="sm"
          className="rounded mx-2 d-flex align-items-center"
          onClick={() => onSeeAssignations(rowData.row.original)}
        >
          <FontAwesomeIcon icon={faUsers} className="mr-1" />
          <span>Assignments</span>
        </Button>
        {!IS_READ_ONLY ? (
          <Button
            color="warning"
            size="sm"
            className="rounded d-flex align-items-center"
            onClick={() => onEdit(rowData.row.original.id)}
          >
            <FontAwesomeIcon icon={faPen} className="mr-1" />
            <span>Edit</span>
          </Button>
        ) : null}
      </div>
    ),
  },
];

const Employees = () => {
  const [authContext] = useAuth();
  const [employeesContext, setEmployeesContext] = useEmployees();

  const [loading, setLoading] = useState(true);
  const [assignationsModal, setAssignationsModal] = useState();
  const [editModal, setEditModal] = useState();

  const [safetyCertificationsModal, setSafetyCertificationsModal] = useState({
    isOpen: false,
    employee: null,
  });

  const [employeeNotesModal, setEmployeeNotesModal] = useState({
    isOpen: false,
    employeeId: null,
  });

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const [showInactive, setShowInactive] = useState();

  const onEditNotes = (employeeId) => {
    setEmployeeNotesModal({
      isOpen: true,
      employeeId: employeeId,
    });
  };

  const onSafetyDocs = (employee) => {
    setSafetyCertificationsModal({
      isOpen: true,
      employee,
    });
  };

  const onSort = useCallback(
    ([data]) => {
      if (data) {
        const sortBy = data.id;
        const direction = data.desc ? "desc" : "asc";
        if (
          employeesContext.sortBy === sortBy?.id &&
          employeesContext.direction === direction
        ) {
          return;
        }
        setEmployeesContext({
          action: ACTIONS.SORT,
          payload: { sortBy, direction },
        });
      } else {
        setEmployeesContext({
          action: ACTIONS.SORT,
          payload: { sortBy: null, direction: null },
        });
      }
    },
    [employeesContext.direction, employeesContext.sortBy, setEmployeesContext]
  );

  const setEmployeesContextCb = useCallback(
    (data) => setEmployeesContext(data),
    [setEmployeesContext]
  );

  useEffect(() => {
    setLoading(true);
    employeesApi
      .getEmployees({
        type: DEFAULT,
        search: employeesContext.search,
        page: employeesContext.page - 1,
        pageSize: employeesContext.sizePerPage,
        sortBy: employeesContext.sortBy,
        direction: employeesContext.direction,
        isActive: showInactive ? "false" : "true",
        jobSourceId: authContext.currentUser.jobSourceId,
      })
      .then((employees) => {
        setEmployeesContextCb({
          action: ACTIONS.GET_EMPLOYEES_SUCCESS,
          payload: { employees },
        });
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [
    setEmployeesContextCb,
    employeesContext.sortBy,
    employeesContext.direction,
    employeesContext.sizePerPage,
    employeesContext.page,
    employeesContext.search,
    employeesContext.refresh,
    showInactive,
    authContext.currentUser.jobSourceId,
  ]);

  const IS_READ_ONLY = utilsHelper.isReadOnly(authContext);

  return (
    <Container fluid className="flex-grow-1 flex-column d-flex">
      <div className="w-100">
        <AdvanceTableWrapper
          columns={columns(
            onEditNotes,
            (employee) => setAssignationsModal(employee),
            (employeeId) => setEditModal(employeeId),
            (employee) => onSafetyDocs(employee),
            IS_READ_ONLY
          )}
          data={employeesContext.employees?.data || []}
          pageSize={employeesContext.sizePerPage}
          sortable
          onSort={onSort}
          defaultSort={{
            sortBy: employeesContext.sortBy,
            direction: employeesContext.direction,
          }}
        >
          <Card>
            <CardHeader className="d-flex align-items-center justify-content-between mt-2">
              <div className="text-dark flex-grow-1 d-flex align-items-center">
                <h3 className="mb-0 ">Employees</h3>
                <small className="text-muted ml-2 pt-1">
                  ({employeesContext.employees?.count || 0})
                </small>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <InputGroup size="m" className="mr-3 border rounded px-2 py-1">
                  <CustomCheckboxWithLabel
                    label="Show Inactive"
                    checked={showInactive}
                    onClick={() => setShowInactive(!showInactive)}
                  />
                </InputGroup>
                <InputGroup size="m" className="mr-3">
                  <Input
                    maxLength="50"
                    placeholder="Search for.."
                    value={employeesContext.search}
                    onChange={(evt) =>
                      setEmployeesContext({
                        action: ACTIONS.SEARCH,
                        payload: { search: evt.target.value },
                      })
                    }
                  />
                  <InputGroupText className="search-input input-group-text bg-primary text-white border-left-0 border-primary">
                    <FontAwesomeIcon icon={faSearch} />
                  </InputGroupText>
                </InputGroup>
                <Button
                  size="sm"
                  className="mr-3 rounded-circle d-flex custom-rounded-button"
                  color="primary"
                  onClick={() =>
                    setEmployeesContext({
                      action: ACTIONS.REFRESH,
                    })
                  }
                >
                  <FontAwesomeIcon icon={faSync} />
                </Button>
              </div>
            </CardHeader>
            <CardBody className="overflow-x-auto">
              <AdvanceTable
                table
                isLoading={loading}
                headerClassName="text-muted small"
                style={{ minWidth: 1500 }}
                tableProps={{
                  striped: true,
                  className: "mb-0",
                }}
              />
            </CardBody>
            <CardFooter>
              <AdvanceTablePagination
                totalCount={employeesContext.employees?.count || 0}
                pageCount={employeesContext.employees.totalPages}
                currentPage={employeesContext.page - 1}
                onPageChange={(page) =>
                  setEmployeesContext({
                    action: ACTIONS.PAGE_CHANGE,
                    payload: { page },
                  })
                }
                pageSize={employeesContext.sizePerPage}
                onPageSizeChange={(sizePerPage) =>
                  setEmployeesContext({
                    action: ACTIONS.PAGE_SIZE_CHANGE,
                    payload: { sizePerPage },
                  })
                }
              />
            </CardFooter>
          </Card>
        </AdvanceTableWrapper>
      </div>
      {assignationsModal ? (
        <EmployeeAssignationsModal
          onClose={() => setAssignationsModal(false)}
          employee={assignationsModal}
        />
      ) : editModal ? (
        <EmployeeModal
          employeeId={editModal}
          onClose={() => setEditModal(false)}
          onSubmit={() => {
            setEditModal(false);
            setEmployeesContext({
              action: ACTIONS.REFRESH,
            });
          }}
        />
      ) : employeeNotesModal.isOpen ? (
        <EmployeeNotesModal
          employeeId={employeeNotesModal.employeeId}
          onClose={() =>
            setEmployeeNotesModal({
              isOpen: false,
            })
          }
        />
      ) : informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : safetyCertificationsModal.isOpen ? (
        <SafetyCertificationsModal
          employee={safetyCertificationsModal.employee}
          onClose={() => setSafetyCertificationsModal({ isOpen: false })}
        />
      ) : null}
    </Container>
  );
};

export default Employees;
